<template>
	<div id="ag-grid-demo">
		<div id="email-loader" class="vx-card mb-base vs-con-loading__container">
			<div class="vx-card__header">
				<div class="vx-card__title">
					<h4>Registrant Details</h4>
				</div>
			</div>
			<div class="vx-card__collapsible-content vs-con-loading__container">
				<div class="p-6 pt-0">
					<ag-grid-vue
						ref="agGridTable"
						:components="components"
						:gridOptions="gridOptions"
						class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--small"
						:columnDefs="columnDefs"
						:defaultColDef="defaultColDef"
						:rowData="tableData"
						rowSelection="single"
						:enableRangeSelection="true"
						:suppressCopyRowsToClipboard="true"
						colResizeDefault="shift"
						:suppressDragLeaveHidesColumns="true"
						:animateRows="false"
						:floatingFilter="true"
						:pagination="true"
						:paginationPageSize="paginationPageSize"
						:suppressPaginationPanel="true"
						:suppressRowClickSelection="true"
						:rowClassRules="rowClassRules"
						:statusBar="statusBar"
						:sideBar="sideBar"
						:rowHeight="38"
						:getContextMenuItems="getContextMenuItems"
						@displayed-columns-changed="displayedColumnsChanged"
						@column-resized="onColumnResized">
					</ag-grid-vue>
					<div class="vx-row">
						<div class="vx-col w-full">
							<vs-pagination
							:total="totalPages"
							:max="maxPageNumbers"
							v-model="currentPage" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	// Cell Renderer
	import CellRendererEncryptedEmail from '@/components/ag-grid-tables/cell-renderer/EncryptedEmail.vue'
	import CellRendererEncryptedPhone from '@/components/ag-grid-tables/cell-renderer/EncryptedPhone.vue'

	export default {
		components: {
			AgGridVue,
			CellRendererEncryptedEmail,
			CellRendererEncryptedPhone
		},
        props: {
            suggestionsData: {
                type: Array
			},
		},
		data() {
			return {
				isGridReady: false,
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				modifiedColumnState: null,
				columnDefs: [
				{
					headerName: 'Email',
					field: 'email',
					minWidth: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRendererFramework: 'CellRendererEncryptedEmail'
				},
				{
					headerName: 'Registrant ID',
					field: 'registrant_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Registrant ROID',
					field: 'registrant_roid',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Name',
					field: 'name',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Organization',
					field: 'organization',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Address Street',
					field: 'address_street',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'City',
					field: 'city',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'State',
					field: 'state',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Zip',
					field: 'postal_code_zip',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Country',
					field: 'country',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Phone',
					field: 'phone_voice',
					filter: "agTextColumnFilter",
					minWidth: 200,
					cellRendererFramework: 'CellRendererEncryptedPhone'
				},
				{
					headerName: 'Fax',
					field: 'fax',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				],

				// Cell Renderer Components
				components: {
					CellRendererEncryptedEmail,
					CellRendererEncryptedPhone
				},


				whitelistNode: {
					email: null,
					flag: 0,
				},
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			tableData(val) {
				if (val.length) {
					// Set column state
					this.setColumnStateFromLocalstorage();
				}
			},
			modifiedColumnState: {
				handler: function(val) {
					localStorage.setItem('col-registrant-search-suggestions-'+this.$route.name, JSON.stringify({'state': val}));
				},
				deep: true
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			tableData() {
				return this.suggestionsData.length ? this.suggestionsData.map(x => x) : [];
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#email-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#email-loader > .con-vs-loading");
			},
			setColumnStateFromLocalstorage() {
				// Set isGridReady Flag
				this.isGridReady = true;
				// Set Column State
				let columnState = JSON.parse(localStorage.getItem('col-registrant-search-suggestions-'+this.$route.name));
				if(columnState) {
					this.gridOptions.columnApi.setColumnState(columnState.state);
				}
			},
			displayedColumnsChanged() {
				if (this.isGridReady) {
					this.modifiedColumnState = this.gridOptions.columnApi.getColumnState();
				}
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			whitelistConfirm(email) {
				// Store whitelist obj
				this.whitelistNode['email'] = email;

				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Whitelist the selected email?',
					accept: this.whitelistEmail
				});
			},
			whitelistEmail() {
				let self = this;

				// Show loader
				this.showDivLoading();
				
				// Whitelist Axios Call
				this.$axiosSecure.post('/whitelist-email', {
					email: this.whitelistNode['email']
				})
				.then((success) => {
					if(success.data == "created") {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Whitelist success',
							text: 'The selected email has been whitelisted'
						});
					}
					else {
						// Confimation notification
						self.$vs.notify({
							color: 'warning',
							title: 'Whitelist error',
							text: 'The selected email is already whitelisted'
						});
					}
						
					// Hide loader
					self.hideDivLoading();
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems() {
				let result;

				result = [
					'copy',
					'copyWithHeaders',
					'paste',
					'separator',
					'export'
				];

				return result;
			}
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

			// Set column state
			this.setColumnStateFromLocalstorage();
		}
	}

</script>