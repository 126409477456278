<template>
    <div>
        <div class="flex justify-between">
            <div class="flex-grow min-w-0">
                <span class="block truncate w-full">{{ params.value }}</span>
            </div>
            <feather-icon icon="EyeIcon" svgClasses="h-4 w-4 ml-4 cursor-pointer" @click="viewDecryptedValue()"/>

        </div>

        <vs-prompt id="password-prompt" vs-title="View Decrypted Value" @vs-cancel="closePasswordPrompt" @vs-close="closePasswordPrompt" :vs-buttons-hidden="true" :vs-active.sync="password['prompt']">
            <div>
				<vs-input v-model="password['input']" class="w-full" placeholder="Enter password"/>
			</div>
        </vs-prompt>

        <vs-prompt id="decrypted-phone-prompt" vs-title="View Decrypted Phone" @vs-cancel="closeDecryptedPhonePrompt" @vs-close="closeDecryptedPhonePrompt" :vs-buttons-hidden="true" :vs-active.sync="decrypted['prompt']">
            <vs-alert active="true" class="h-auto" :class="{ 'mb-4': !!(decrypted['value']) }">
                <div class="break-all">
                    <template v-if="decrypted['value']">{{ decrypted['value'] }}</template>
                    <template v-else>Value is being decrypted, please wait...</template>
                </div>
            </vs-alert>
            <div v-if="decrypted['value']" class="vx-row">
                <div class="vx-col w-full">
                    <vs-button v-if="!(!!(decrypted['blacklist']))" color="dark" type="border" class="w-full" @click="blacklistConfirm()">Blacklist</vs-button>
                    <vs-button v-else color="dark" type="border" class="w-full" @click="blacklistConfirm()">De-Blacklist</vs-button>
                </div>
            </div>
        </vs-prompt>
    </div>
</template>

<script>
    export default {
        name: 'CellRendererEncryptedPhone',
		data() {
			return {
				password: {
					input: '',
					value: 'ea50da49e0be',
					prompt: false
				},
				decrypted: {
                    value: '',
                    blacklist: 0,
					prompt: false
				},
			}
		},
        watch: {
			'password.input'() {
                if (this.password['input'] == this.password['value']) {
                    this.acceptPasswordPrompt();
                }
            }
        },
        methods: {
			viewDecryptedValue() {
                // Check PIN cache
                if (this.getLocalStorageWithExpiry('encryptedPhone')) {
                    // Open Phone Prompt
                    this.openDecryptedPhonePrompt();
                }
                else {
                    // Open Password Prompt
                    this.password['prompt'] = true;
                }
            },
			closePasswordPrompt() {
                this.password['input'] = '';
                this.password['prompt'] = false;
            },
			acceptPasswordPrompt() {
                // Close Password Prompt
                this.closePasswordPrompt();

                // Set cache for PIN
                this.setLocalStorageWithExpiry('encryptedPhone', this.params.value, 1800000);

                // Open Phone Prompt
                this.openDecryptedPhonePrompt();
            },
			openDecryptedPhonePrompt() {
                this.decrypted['value'] = '';
                this.decrypted['prompt'] = true;
                
                // API call to get value
                this.$axiosSecure.get('/decrypt', {
                    params: {
                        telephone: this.params.value
                    }
                })
                .then((response) => {
                    this.decrypted['value'] = response.data.decrypted_telephone != undefined ? response.data.decrypted_telephone : this.params.value;
                    this.decrypted['blacklist'] = Number(response.data.blacklisted_telephone);
                })
                .catch((error) => {
                    // Error notification
                    this.$vs.notify({
                        color: 'danger',
                        title: 'Something went wrong',
                        text: 'Please contact the server admin'
                    });

                    console.log(error);
                });
            },
			closeDecryptedPhonePrompt() {
                // Reset Decrypted Prompt
                this.decrypted['prompt'] = false;
            },
			blacklistConfirm() {
                // Close Decrypted Prompt
                this.closeDecryptedPhonePrompt();

                // Check blacklist status
				if(this.decrypted['blacklist']) {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Remove the selected phone from blacklist?',
						accept: this.blacklistPhone
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Blacklist the selected phone?',
						accept: this.blacklistPhone
					});
				}
            },
            blacklistPhone() {
                // Check blacklist status
				if(this.decrypted['blacklist']) {
                    // De-Blacklist Axios Call
                    this.$axiosSecure.post('/remove-blacklisted-registrant-telephone', {
                        telephone: this.params.value
                    })
                    .then(() => {
                        // Confimation notification
                        this.$vs.notify({
                            color: 'success',
                            title: 'Blacklist success',
                            text: 'The selected phone has been removed from blacklist'
                        });
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
                else {
                    // Blacklist Axios Call
                    this.$axiosSecure.post('/save-blacklisted-registrant-telephone', {
                        telephone: this.params.value
                    })
                    .then((success) => {
                        if(success.data == "created") {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'success',
                                title: 'Blacklist success',
                                text: 'The selected phone has been blacklisted'
                            });
                        }
                        else {
                            // Confimation notification
                            this.$vs.notify({
                                color: 'warning',
                                title: 'Blacklist error',
                                text: 'The selected phone is already blacklisted'
                            });
                        }
                    })
                    .catch((error) => {
                        // Error notification
                        this.$vs.notify({
                            color: 'danger',
                            title: 'Something went wrong',
                            text: 'Please contact the server admin'
                        });

                        console.log(error);
                    });
                }
            }
        }
    }
</script>
